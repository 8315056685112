<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <h1>Error 404</h1>
    <h2>Vous allez être redirigé sur la page d'accueil</h2>
  </div>
</template>

<script>
  export default {
    name: 'Error404',
    created() {
      setTimeout(()=> {
        this.$router.push('/')
      }, 3000)
    }
  }
</script>
